import { j as s, T as _e, c as N, s as Fe, C as ze, p as P, L as ke, H as Ee, I as Be, a as Oe, D as $e, R as Me, b as De, d as Pe } from "./Tile-BpO5qp1x.mjs";
import { A as Tr, B as Cr, e as Nr, J as Sr, f as Lr, g as jr, h as Ir, i as Fr, k as zr } from "./Tile-BpO5qp1x.mjs";
import j, { forwardRef as q, useMemo as D, useState as $, useRef as L, useCallback as f, useEffect as I, useId as ee, useLayoutEffect as Ae } from "react";
import { createPortal as Re } from "react-dom";
async function He(e) {
  if (!e)
    return !1;
  await navigator.clipboard.writeText(e);
}
const We = (e, t) => Number(e === "en" ? String(t).replace(/[^0-9.]/g, "") : String(t).replace(/[^0-9,]/g, "").replace(/,/g, ".")), pe = {}, qe = (e, t, r, o) => {
  const a = `${e}-${t ? "currency" : "noCurrency"}-${r}-${o}`;
  let c = pe[a];
  return c || (c = new Intl.NumberFormat(e ?? "de", {
    minimumFractionDigits: r,
    maximumFractionDigits: r,
    ...t ? { style: "currency", currency: "EUR" } : {},
    ...o ? {} : { useGrouping: !1 }
  }), pe[a] = c), c;
};
function ce(e, t, r = {}) {
  const o = (r == null ? void 0 : r.precision) === void 0 ? 2 : r == null ? void 0 : r.precision, n = typeof t == "number" ? t || 0 : We(e, t), a = (r == null ? void 0 : r.thousandSeparator) ?? !0;
  return qe(e, !(r != null && r.format), o, a).format(n).replace(/\u00A0/, " ");
}
function me(e) {
  if (!e)
    return 0;
  const t = e.replace(/,/g, "."), r = parseFloat(t) || 0;
  return Math.round(r * 100);
}
function J(e, t = 2) {
  return e.replace(/[^\d.,]/g, "").replace(/^([.,]{1,1})/, "0$1").replace(/^0{2,}/, "0").replace(/^0([\d]+)([\d,.]*)/, "$1$2").replace(/[.,]([^.,]*)(?=[.,])/g, "$1").replace(new RegExp(`([.,]\\d{${t}}).*$`), "$1");
}
const ur = [
  "text",
  "number",
  "email",
  "password",
  "search",
  "tel",
  "date",
  "datetime-local",
  "time",
  "url",
  "color"
];
function be(e) {
  if (typeof e > "u") return;
  let t;
  if (typeof e != "number" ? t = parseFloat(e) : t = e, !isNaN(t))
    return Math.round(t * 100);
}
const Ke = q(
  ({
    label: e,
    locale: t = "de",
    name: r,
    id: o,
    min: n = 0,
    onChange: a,
    onBlur: c,
    onFocus: l,
    defaultValue: p,
    value: i,
    step: d,
    children: g,
    className: _,
    hideSteps: T,
    max: k,
    ...x
  }, h) => {
    const m = D(() => Ue(d), [d]), [b, C] = $(
      J(
        ce(t, i ?? p ?? "", { precision: m, format: "%v", thousandSeparator: !1 }),
        m
      )
    ), E = D(() => be(k), [k]), u = D(() => be(n), [n]), [w, A] = $(null), O = L(null), Y = f(
      (v) => {
        if (O.current = v, !!h) {
          if (typeof h == "function") return h(v);
          h.current = v;
        }
      },
      [h]
    ), y = f(
      (v) => {
        const B = fe(v.target.value, t, m), re = me(B);
        if (typeof E < "u" && re > E || typeof u < "u" && re < u) {
          const Ie = v.target.selectionStart ? v.target.selectionStart : 0;
          return A(Ie), v.preventDefault();
        }
        A(v.target.selectionStart);
        let ue = v.target.value;
        B.startsWith(v.target.value) || (ue = B);
        const de = me(v.target.value);
        v.target.value && !isNaN(de) && re === de && (a == null || a(v)), C(ue);
      },
      [t, m, u, E, a]
    ), S = f(
      (v) => {
        const B = fe(v.target.value, t, m);
        B !== v.target.value && (v.target.value = B), A(null), c == null || c(v);
      },
      [c, m, t]
    ), F = f(
      (v) => {
        A(v.target.selectionStart), l == null || l(v);
      },
      [l]
    ), K = D(
      () => p ? { defaultValue: b } : { value: b },
      [p, b]
    );
    return I(() => {
      if (typeof p < "u") return;
      const v = i ?? p;
      if (typeof i > "u")
        return C("");
      const B = J(
        ce(t, v, { precision: m, format: "%v", thousandSeparator: !1 }),
        m
      );
      C(B);
    }, [i, m, p, t]), I(() => {
      const v = (b == null ? void 0 : b.length) ?? 0, B = (w ?? 0) > v ? v : w;
      w === null || !O.current || document.activeElement !== O.current || O.current.setSelectionRange(B, B);
    }, [w, b]), /* @__PURE__ */ s.jsx(
      _e,
      {
        ...x,
        ...K,
        onFocus: F,
        onBlur: S,
        onChange: y,
        step: d,
        ref: Y,
        id: o || r,
        name: r,
        label: e,
        pattern: "[0-9]*[.,]?[0-9]*",
        type: "text",
        inputMode: "decimal",
        className: N(_, { [Fe.hideSteps]: T }),
        children: g
      }
    );
  }
);
Ke.displayName = "ClientAmountField";
function fe(e, t, r = 2) {
  if (typeof e > "u" || e === null)
    return e;
  const o = String(e), n = J(o, r), a = ce(t, n, { precision: r, format: "%v", thousandSeparator: !1 }), c = J(a, r);
  if (!r)
    return c;
  const l = c.search(/[.,]/);
  if (l < 0)
    return c;
  const p = r - (c.length - 1 - l);
  return c.concat("0".repeat(p));
}
function Ue(e) {
  var r;
  return e === void 0 ? void 0 : ((r = String(e).split(".")[1]) == null ? void 0 : r.length) ?? 0;
}
const dr = q(function({ onChange: t, onClick: r, ...o }, n) {
  const { disabled: a } = o, c = a ? P : t, l = a ? P : r;
  return /* @__PURE__ */ s.jsx(ze, { ...o, onChange: c, onClick: l, ref: n });
}), Xe = "_picker_cqmcr_1", Ye = "_swatch_cqmcr_6", Ge = "_activeSwatch_cqmcr_21", Qe = "_inactiveSwatch_cqmcr_30", Ve = "_swatchInner_cqmcr_35", Je = "_activePickerSwatch_cqmcr_42", Ze = "_popover_cqmcr_51", et = "_pickerSwatches_cqmcr_73", tt = "_input_cqmcr_85", M = {
  picker: Xe,
  swatch: Ye,
  activeSwatch: Ge,
  inactiveSwatch: Qe,
  swatchInner: Ve,
  activePickerSwatch: Je,
  popover: Ze,
  pickerSwatches: et,
  input: tt
};
function te() {
  return (te = Object.assign || function(e) {
    for (var t = 1; t < arguments.length; t++) {
      var r = arguments[t];
      for (var o in r) Object.prototype.hasOwnProperty.call(r, o) && (e[o] = r[o]);
    }
    return e;
  }).apply(this, arguments);
}
function se(e, t) {
  if (e == null) return {};
  var r, o, n = {}, a = Object.keys(e);
  for (o = 0; o < a.length; o++) t.indexOf(r = a[o]) >= 0 || (n[r] = e[r]);
  return n;
}
function Z(e) {
  var t = L(e), r = L(function(o) {
    t.current && t.current(o);
  });
  return t.current = e, r.current;
}
var Q = function(e, t, r) {
  return t === void 0 && (t = 0), r === void 0 && (r = 1), e > r ? r : e < t ? t : e;
}, G = function(e) {
  return "touches" in e;
}, le = function(e) {
  return e && e.ownerDocument.defaultView || self;
}, he = function(e, t, r) {
  var o = e.getBoundingClientRect(), n = G(t) ? function(a, c) {
    for (var l = 0; l < a.length; l++) if (a[l].identifier === c) return a[l];
    return a[0];
  }(t.touches, r) : t;
  return { left: Q((n.pageX - (o.left + le(e).pageXOffset)) / o.width), top: Q((n.pageY - (o.top + le(e).pageYOffset)) / o.height) };
}, ge = function(e) {
  !G(e) && e.preventDefault();
}, Te = j.memo(function(e) {
  var t = e.onMove, r = e.onKey, o = se(e, ["onMove", "onKey"]), n = L(null), a = Z(t), c = Z(r), l = L(null), p = L(!1), i = D(function() {
    var T = function(h) {
      ge(h), (G(h) ? h.touches.length > 0 : h.buttons > 0) && n.current ? a(he(n.current, h, l.current)) : x(!1);
    }, k = function() {
      return x(!1);
    };
    function x(h) {
      var m = p.current, b = le(n.current), C = h ? b.addEventListener : b.removeEventListener;
      C(m ? "touchmove" : "mousemove", T), C(m ? "touchend" : "mouseup", k);
    }
    return [function(h) {
      var m = h.nativeEvent, b = n.current;
      if (b && (ge(m), !function(E, u) {
        return u && !G(E);
      }(m, p.current) && b)) {
        if (G(m)) {
          p.current = !0;
          var C = m.changedTouches || [];
          C.length && (l.current = C[0].identifier);
        }
        b.focus(), a(he(b, m, l.current)), x(!0);
      }
    }, function(h) {
      var m = h.which || h.keyCode;
      m < 37 || m > 40 || (h.preventDefault(), c({ left: m === 39 ? 0.05 : m === 37 ? -0.05 : 0, top: m === 40 ? 0.05 : m === 38 ? -0.05 : 0 }));
    }, x];
  }, [c, a]), d = i[0], g = i[1], _ = i[2];
  return I(function() {
    return _;
  }, [_]), j.createElement("div", te({}, o, { onTouchStart: d, onMouseDown: d, className: "react-colorful__interactive", ref: n, onKeyDown: g, tabIndex: 0, role: "slider" }));
}), Ce = function(e) {
  return e.filter(Boolean).join(" ");
}, Ne = function(e) {
  var t = e.color, r = e.left, o = e.top, n = o === void 0 ? 0.5 : o, a = Ce(["react-colorful__pointer", e.className]);
  return j.createElement("div", { className: a, style: { top: 100 * n + "%", left: 100 * r + "%" } }, j.createElement("div", { className: "react-colorful__pointer-fill", style: { backgroundColor: t } }));
}, R = function(e, t, r) {
  return t === void 0 && (t = 0), r === void 0 && (r = Math.pow(10, t)), Math.round(r * e) / r;
}, rt = function(e) {
  var t = e.s, r = e.v, o = e.a, n = (200 - t) * r / 100;
  return { h: R(e.h), s: R(n > 0 && n < 200 ? t * r / 100 / (n <= 100 ? n : 200 - n) * 100 : 0), l: R(n / 2), a: R(o, 2) };
}, ie = function(e) {
  var t = rt(e);
  return "hsl(" + t.h + ", " + t.s + "%, " + t.l + "%)";
};
j.memo(function(e) {
  var t = e.hue, r = e.onChange, o = Ce(["react-colorful__hue", e.className]);
  return j.createElement("div", { className: o }, j.createElement(Te, { onMove: function(n) {
    r({ h: 360 * n.left });
  }, onKey: function(n) {
    r({ h: Q(t + 360 * n.left, 0, 360) });
  }, "aria-label": "Hue", "aria-valuenow": R(t), "aria-valuemax": "360", "aria-valuemin": "0" }, j.createElement(Ne, { className: "react-colorful__hue-pointer", left: t / 360, color: ie({ h: t, s: 100, v: 100, a: 1 }) })));
});
j.memo(function(e) {
  var t = e.hsva, r = e.onChange, o = { backgroundColor: ie({ h: t.h, s: 100, v: 100, a: 1 }) };
  return j.createElement("div", { className: "react-colorful__saturation", style: o }, j.createElement(Te, { onMove: function(n) {
    r({ s: 100 * n.left, v: 100 - 100 * n.top });
  }, onKey: function(n) {
    r({ s: Q(t.s + 100 * n.left, 0, 100), v: Q(t.v - 100 * n.top, 0, 100) });
  }, "aria-label": "Color", "aria-valuetext": "Saturation " + R(t.s) + "%, Brightness " + R(t.v) + "%" }, j.createElement(Ne, { className: "react-colorful__saturation-pointer", top: 1 - t.v / 100, left: t.s / 100, color: ie(t) })));
});
var ot = /^#?([0-9A-F]{3,8})$/i, nt = function(e) {
  var t = e.color, r = t === void 0 ? "" : t, o = e.onChange, n = e.onBlur, a = e.escape, c = e.validate, l = e.format, p = e.process, i = se(e, ["color", "onChange", "onBlur", "escape", "validate", "format", "process"]), d = $(function() {
    return a(r);
  }), g = d[0], _ = d[1], T = Z(o), k = Z(n), x = f(function(m) {
    var b = a(m.target.value);
    _(b), c(b) && T(p ? p(b) : b);
  }, [a, p, c, T]), h = f(function(m) {
    c(m.target.value) || _(a(r)), k(m);
  }, [r, a, c, k]);
  return I(function() {
    _(a(r));
  }, [r, a]), j.createElement("input", te({}, i, { value: l ? l(g) : g, spellCheck: "false", onChange: x, onBlur: h }));
}, ye = function(e) {
  return "#" + e;
}, at = function(e) {
  var t = e.prefixed, r = e.alpha, o = se(e, ["prefixed", "alpha"]), n = f(function(c) {
    return c.replace(/([^0-9A-F]+)/gi, "").substring(0, r ? 8 : 6);
  }, [r]), a = f(function(c) {
    return function(l, p) {
      var i = ot.exec(l), d = i ? i[1].length : 0;
      return d === 3 || d === 6 || !!p && d === 4 || !!p && d === 8;
    }(c, r);
  }, [r]);
  return j.createElement(nt, te({}, o, { escape: n, format: t ? ye : void 0, process: ye, validate: a }));
};
const ct = {
  "betterplace-dimension-0": "1rem",
  "betterplace-dimension-1": "1.25rem",
  "betterplace-dimension-2": "1.5rem",
  "betterplace-dimension-3": "2rem",
  "betterplace-dimension-4": "3rem",
  "betterplace-dimension-5": "4rem",
  "betterplace-dimension-6": "5rem",
  "betterplace-dimension-7": "6.25rem",
  "betterplace-dimension-8": "12.5rem",
  "betterplace-dimension-9": "20rem",
  "betterplace-dimension-10": "30rem",
  "betterplace-dimension--1": "0.75rem",
  "betterplace-dimension--2": "0.5rem",
  "betterplace-dimension--3": "0.25rem",
  "betterplace-dimension--4": "0.125rem",
  "betterplace-dimension--5": "0.0625rem",
  "betterplace-dimension-none": "0rem",
  "betterplace-dimension-negative-1": "-0.75rem",
  "betterplace-dimension-negative-2": "-0.5rem",
  "betterplace-dimension-negative-3": "-0.25rem",
  "betterplace-dimension-negative-4": "-0.125rem",
  "betterplace-dimension-negative-5": "-0.0625rem",
  "betterplace-opacity-low": "10%",
  "betterplace-opacity-md": "50%",
  "betterplace-opacity-high": "90%",
  "betterplace-letter-spacing-0": "0%",
  "betterplace-letter-spacing-increased": "150%",
  "betterplace-letter-spacing-decreased": "-5%",
  "betterplace-paragraph-spacing-0": "0",
  "betterplace-paragraph-spacing-md": "0.75rem",
  "betterplace-paragraph-spacing-sm": "0.5rem",
  "betterplace-color-gray-100": "#f8f8f8",
  "betterplace-color-gray-200": "#eeeeee",
  "betterplace-color-gray-300": "#cccccc",
  "betterplace-color-gray-400": "#acacac",
  "betterplace-color-gray-500": "#919191",
  "betterplace-color-gray-600": "#7b7b7b",
  "betterplace-color-gray-700": "#636363",
  "betterplace-color-gray-800": "#3f3f3f",
  "betterplace-color-gray-900": "#282828",
  "betterplace-color-green-100": "#f5fccc",
  "betterplace-color-green-200": "#eaf99a",
  "betterplace-color-green-300": "#d6ef66",
  "betterplace-color-green-400": "#bedf40",
  "betterplace-color-green-500": "#9ecb0a",
  "betterplace-color-green-600": "#85ac1c",
  "betterplace-color-green-700": "#549205",
  "betterplace-color-green-800": "#357c00",
  "betterplace-color-green-900": "#2a5807",
  "betterplace-color-purple-100": "#f7dae8",
  "betterplace-color-purple-200": "#f0b7d8",
  "betterplace-color-purple-300": "#d387ba",
  "betterplace-color-purple-400": "#a75c96",
  "betterplace-color-purple-500": "#833c79",
  "betterplace-color-purple-600": "#6d2c64",
  "betterplace-color-purple-700": "#612058",
  "betterplace-color-purple-800": "#4d1a43",
  "betterplace-color-purple-900": "#381835",
  "betterplace-color-teal-100": "#e4fbee",
  "betterplace-color-teal-200": "#c9f7e2",
  "betterplace-color-teal-300": "#a7e7d0",
  "betterplace-color-teal-400": "#88d0bd",
  "betterplace-color-teal-500": "#60b2a4",
  "betterplace-color-teal-600": "#309b89",
  "betterplace-color-teal-700": "#2b8475",
  "betterplace-color-teal-800": "#1e6761",
  "betterplace-color-teal-900": "#114e47",
  "betterplace-color-yellow-100": "#fff4d2",
  "betterplace-color-yellow-200": "#fbe8af",
  "betterplace-color-yellow-300": "#f9da7c",
  "betterplace-color-yellow-400": "#f6ce46",
  "betterplace-color-yellow-500": "#f3b700",
  "betterplace-color-yellow-600": "#f3a900",
  "betterplace-color-yellow-700": "#f39600",
  "betterplace-color-yellow-800": "#d86c00",
  "betterplace-color-yellow-900": "#c44400",
  "betterplace-color-red-100": "#fee2e2",
  "betterplace-color-red-200": "#ffc4c4",
  "betterplace-color-red-300": "#fca5a5",
  "betterplace-color-red-400": "#f87171",
  "betterplace-color-red-500": "#ef4444",
  "betterplace-color-red-600": "#dc2626",
  "betterplace-color-red-700": "#d32b43",
  "betterplace-color-red-800": "#b11b3e",
  "betterplace-color-red-900": "#931c38",
  "betterplace-color-light": "#ffffff",
  "betterplace-color-darkness": "#000000",
  "betterplace-color-blue-dark": "#245d8c",
  "betterplace-color-opacity-white-40": "rgba(255, 255, 255, 0.4)",
  "betterplace-color-opacity-white-70": "rgba(255, 255, 255, 0.7)",
  "betterplace-color-opacity-black-10": "rgba(0, 0, 0, 0.1)",
  "betterplace-color-opacity-black-20": "rgba(0, 0, 0, 0.2)",
  "betterplace-color-opacity-black-30": "rgba(0, 0, 0, 0.3)",
  "betterplace-color-opacity-black-40": "rgba(0, 0, 0, 0.4)",
  "betterplace-color-opacity-black-50": "rgba(0, 0, 0, 0.5)",
  "betterplace-color-opacity-black-60": "rgba(0, 0, 0, 0.6)",
  "betterplace-color-opacity-black-70": "rgba(0, 0, 0, 0.7)",
  "betterplace-color-opacity-black-80": "rgba(0, 0, 0, 0.8)",
  "betterplace-color-opacity-black-90": "rgba(0, 0, 0, 0.9)",
  "betterplace-me-color-orange-500": "#ff854d",
  "betterplace-me-color-orange-600": "#DB6038",
  "betterplace-me-color-orange-800": "#6F1F06",
  "betterplace-me-color-blue-100": "#E0F4F9",
  "betterplace-me-color-blue-500": "#00A0C8",
  "betterplace-me-color-blue-700": "#005D90",
  "betterplace-me-color-green-special": "#EBF2E6",
  "betterplace-external-color-blue-facebook": "#4E69A2",
  "betterplace-external-color-blue-twitter": "#1DA1F2",
  "betterplace-external-color-pink-instagram": "#E65083",
  "betterplace-external-color-blue-linkedin": "#0A66C2",
  "betterplace-external-color-red-sparkasse": "#ff0000",
  "betterplace-font-families-fira-sans": "Fira Sans",
  "betterplace-font-weights-regular": 400,
  "betterplace-font-weights-bold": 700,
  "betterplace-text-case-none": "none",
  "betterplace-text-case-capitalize": "capitalize",
  "betterplace-text-decoration-none": "none",
  "betterplace-text-decoration-underline": "underline",
  "betterplace-line-heights-xs": 1.1,
  "betterplace-line-heights-sm": 1.2,
  "betterplace-line-heights-base": 1.5,
  "betterplace-variables-min-font-size": "1rem",
  "betterplace-variables-max-font-size": "1.125rem",
  "betterplace-variables-fluid-root-font-size": "min(max(1rem + (90rem - 22.5rem) * (1.125 - 1) / (90 - 22.5), 1rem), 1.125rem)",
  "betterplace-variables-font-size-scale-ratio": "1.1892",
  "betterplace-variables-width-of-zero-glyph": "0.5583",
  "betterplace-units-ch": "min(max(1rem + (90rem - 22.5rem) * (1.125 - 1) / (90 - 22.5), 1rem), 1.125rem) * 0.5583",
  "betterplace-units-rem": "min(max(1rem + (90rem - 22.5rem) * (1.125 - 1) / (90 - 22.5), 1rem), 1.125rem)",
  "betterplace-font-size-0": "1rem",
  "betterplace-font-size-1": "1.189rem",
  "betterplace-font-size-2": "1.414rem",
  "betterplace-font-size-3": "1.682rem",
  "betterplace-font-size-4": "2rem",
  "betterplace-font-size-5": "2.378rem",
  "betterplace-font-size-6": "2.828rem",
  "betterplace-font-size-7": "3.363rem",
  "betterplace-font-size-8": "4rem",
  "betterplace-font-size-9": "4.757rem",
  "betterplace-font-size-10": "5.657rem",
  "betterplace-font-size--3": "0.595rem",
  "betterplace-font-size--2": "0.707rem",
  "betterplace-font-size--1": "0.878rem",
  "betterplace-measure-2xs": "25ch",
  "betterplace-measure-xs": "36ch",
  "betterplace-measure-sm": "49ch",
  "betterplace-measure-md": "64ch",
  "betterplace-measure-lg": "81ch",
  "betterplace-measure-xl": "100ch",
  "betterplace-measure-2xl": "121ch",
  "betterplace-viewport-width": "90rem",
  "betterplace-viewport-min": "22.5rem",
  "betterplace-viewport-max": "90rem",
  "betterplace-typography-xs": {
    fontWeight: 400,
    fontSize: "0.707rem",
    lineHeight: 1.2,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-sm": {
    fontWeight: 400,
    fontSize: "0.878rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-sm-narrow": {
    fontWeight: 400,
    fontSize: "0.878rem",
    lineHeight: 1.2,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-sm-bold": {
    fontWeight: 700,
    fontSize: "0.878rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-sm-bold-narrow": {
    fontWeight: 700,
    fontSize: "0.878rem",
    lineHeight: 1.2,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-base": {
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-base-bold": {
    fontWeight: 700,
    fontSize: "1rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-lg": {
    fontWeight: 400,
    fontSize: "1.189rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-lg-bold": {
    fontWeight: 700,
    fontSize: "1.189rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-xl": {
    fontWeight: 400,
    fontSize: "1.682rem",
    lineHeight: 1.1,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-xl-bold": {
    fontWeight: 700,
    fontSize: "1.682rem",
    lineHeight: 1.1,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-2xl-bold": {
    fontWeight: 700,
    fontSize: "2rem",
    lineHeight: 1.1,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-3xl-bold": {
    fontWeight: 700,
    fontSize: "3.363rem",
    lineHeight: 1.1,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-4xl-bold": {
    fontWeight: 700,
    fontSize: "4rem",
    lineHeight: 1.1,
    fontFamily: "Fira Sans"
  },
  "betterplace-sizing-fix-1": "20rem",
  "betterplace-sizing-fix-2": "23.4375rem",
  "betterplace-sizing-fix-3": "48.75rem",
  "betterplace-sizing-fix-4": "60rem",
  "betterplace-sizing-fix-5": "52.875rem",
  "betterplace-sizing-fix-6": "90rem",
  "betterplace-shadow-outer-neutral-soft-1": {
    x: "0rem",
    y: "0.0625rem",
    blur: "0.125rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-2": {
    x: "0rem",
    y: "0.125rem",
    blur: "0.25rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-3": {
    x: "0rem",
    y: "0.25rem",
    blur: "0.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-4": {
    x: "0rem",
    y: "0.5rem",
    blur: "1rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-5": {
    x: "0rem",
    y: "0.5rem",
    blur: "1.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-6": {
    x: "0rem",
    y: "0.75rem",
    blur: "3rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-hard-1": {
    x: "0rem",
    y: "0rem",
    blur: "0.0625rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-neutral-hard-2": {
    x: "0rem",
    y: "0rem",
    blur: "0.125rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-neutral-hard-3": {
    x: "0rem",
    y: "0rem",
    blur: "0.25rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-neutral-hard-4": {
    x: "0rem",
    y: "0rem",
    blur: "0.5rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-neutral-hard-5": {
    x: "0rem",
    y: "0rem",
    blur: "0.75rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-neutral-hard-6": {
    x: "0rem",
    y: "0rem",
    blur: "1.25rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-green-hard-1": {
    x: "0rem",
    y: "0rem",
    blur: "0.0625rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-green-hard-2": {
    x: "0rem",
    y: "0rem",
    blur: "0.125rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-green-hard-3": {
    x: "0rem",
    y: "0rem",
    blur: "0.25rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-green-hard-4": {
    x: "0rem",
    y: "0rem",
    blur: "0.5rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-green-hard-5": {
    x: "0rem",
    y: "0rem",
    blur: "0.75rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-green-hard-6": {
    x: "0rem",
    y: "0rem",
    blur: "1.25rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-red-hard-1": {
    x: "0rem",
    y: "0rem",
    blur: "0.0625rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-red-hard-2": {
    x: "0rem",
    y: "0rem",
    blur: "0.125rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-red-hard-3": {
    x: "0rem",
    y: "0rem",
    blur: "0.25rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-red-hard-4": {
    x: "0rem",
    y: "0rem",
    blur: "0.5rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-red-hard-5": {
    x: "0rem",
    y: "0rem",
    blur: "0.75rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-red-hard-6": {
    x: "0rem",
    y: "0rem",
    blur: "1.25rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-1": {
    x: "0rem",
    y: "-0.0625rem",
    blur: "0.125rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-2": {
    x: "0rem",
    y: "-0.125rem",
    blur: "0.25rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-3": {
    x: "0rem",
    y: "-0.25rem",
    blur: "0.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-4": {
    x: "0rem",
    y: "-0.5rem",
    blur: "1rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-5": {
    x: "0rem",
    y: "-0.5rem",
    blur: "1.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-6": {
    x: "0rem",
    y: "-0.75rem",
    blur: "3rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-1": {
    x: "0rem",
    y: "0.0625rem",
    blur: "0.125rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-2": {
    x: "0rem",
    y: "0.125rem",
    blur: "0.25rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-3": {
    x: "0rem",
    y: "0.25rem",
    blur: "0.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-4": {
    x: "0rem",
    y: "0.5rem",
    blur: "1rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-5": {
    x: "0rem",
    y: "0.5rem",
    blur: "1.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-6": {
    x: "0rem",
    y: "0.75rem",
    blur: "3rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-red-1": {
    x: "0rem",
    y: "0.0625rem",
    blur: "0.125rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-red-2": {
    x: "0rem",
    y: "0.125rem",
    blur: "0.25rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-red-3": {
    x: "0rem",
    y: "0.25rem",
    blur: "0.5rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-red-4": {
    x: "0rem",
    y: "0.5rem",
    blur: "1rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-red-5": {
    x: "0rem",
    y: "0.5rem",
    blur: "1.5rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-red-6": {
    x: "0rem",
    y: "0.75rem",
    blur: "3rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-green-1": {
    x: "0rem",
    y: "0.0625rem",
    blur: "0.125rem",
    color: "#2a5807"
  },
  "betterplace-shadow-inner-green-2": {
    x: "0rem",
    y: "0.125rem",
    blur: "0.25rem",
    color: "#2a5807"
  },
  "betterplace-shadow-inner-green-3": {
    x: "0rem",
    y: "0.25rem",
    blur: "0.5rem",
    color: "#2a5807"
  },
  "betterplace-shadow-inner-green-4": {
    x: "0rem",
    y: "0.5rem",
    blur: "1rem",
    color: "#2a5807"
  },
  "betterplace-shadow-inner-green-5": {
    x: "0rem",
    y: "0.5rem",
    blur: "1.5rem",
    color: "#2a5807"
  },
  "betterplace-shadow-inner-green-6": {
    x: "0rem",
    y: "0.75rem",
    blur: "3rem",
    color: "#2a5807"
  }
}, lt = (e) => e.substring(1, 3) === "00" && e.length === 9 ? "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABlBMVEX////Z5exdL3Q7AAAAGklEQVQY02NgAAFGRkaa0gzo4gzo6hgGiTsANBAAYf1ZdsIAAAAASUVORK5CYII=')" : e;
function z(e) {
  const t = ct[e];
  return typeof t != "object" && typeof t != "number" ? t : "#";
}
const it = ["mousedown", "touchstart"], st = (e, t, r = it) => {
  const o = f(
    (n) => {
      e.current && n.target instanceof Element && !e.current.contains(n.target) && t();
    },
    [t, e]
  );
  I(() => {
    for (const n of r)
      document.addEventListener(n, o);
    return () => {
      for (const n of r)
        document.removeEventListener(n, o);
    };
  });
}, ut = (e) => {
  const t = f(
    (r) => {
      r.key === "Escape" && e();
    },
    [e]
  );
  I(() => (document.addEventListener("keydown", t), () => {
    document.removeEventListener("keydown", t);
  }), [t]);
}, dt = () => {
  const [e, t] = $(!1), r = f(
    (c) => {
      c && c.preventDefault(), t(!0);
    },
    [t]
  ), o = f(
    (c) => {
      c && c.preventDefault(), t(!1);
    },
    [t]
  ), n = f(
    (c) => {
      c && c.preventDefault(), t(!e);
    },
    [t, e]
  );
  ut(o);
  const a = D(
    () => e ? /* @__PURE__ */ s.jsx(
      "div",
      {
        "aria-hidden": "true",
        className: "modal",
        style: { display: "block", background: "black", opacity: 0.1 },
        onClick: () => t(!1)
      }
    ) : null,
    [t, e]
  );
  return { active: e, background: a, hide: o, show: r, toggle: n };
}, xe = q(function({
  onClick: t,
  color: r,
  triggerSwatch: o = !1,
  className: n
}, a) {
  return /* @__PURE__ */ s.jsxs(
    "button",
    {
      className: N(M.swatch, n, {
        [M.activeSwatch]: o
      }),
      onClick: t,
      ref: a,
      "aria-roledescription": o ? "colour well" : "button",
      children: [
        /* @__PURE__ */ s.jsx(
          "div",
          {
            style: {
              background: lt(r)
            },
            className: M.swatchInner
          }
        ),
        /* @__PURE__ */ s.jsx("span", { className: "sr-only", children: r })
      ]
    }
  );
});
function pt({ color: e, presetColors: t, onChange: r, hexInputLabel: o }) {
  const n = L(null), a = L(null), c = dt(), l = f(
    (i) => {
      i.key === "Enter" && (i.preventDefault(), c.hide());
    },
    [c]
  ), p = f(() => {
    var i;
    (i = a.current) == null || i.focus(), c.hide();
  }, [c]);
  return st(n, p), /* @__PURE__ */ s.jsxs("div", { className: M.picker, children: [
    /* @__PURE__ */ s.jsx(
      xe,
      {
        color: e,
        onClick: (i) => {
          i.preventDefault(), c.toggle();
        },
        ref: a,
        triggerSwatch: !0
      }
    ),
    c.active && /* @__PURE__ */ s.jsx("div", { className: M.popover, ref: n, children: /* @__PURE__ */ s.jsxs("div", { className: M.pickerSwatches, children: [
      t.map((i) => /* @__PURE__ */ s.jsx(
        xe,
        {
          color: i,
          onClick: (d) => {
            d.preventDefault(), p(), r(i);
          },
          className: N({
            [M.activeSwatch]: i === e,
            [M.inactiveSwatch]: i !== e
          })
        },
        i
      )),
      /* @__PURE__ */ s.jsx("label", { htmlFor: "hexColorInput", className: "sr-only", children: o }),
      /* @__PURE__ */ s.jsx(
        at,
        {
          id: "hexColorInput",
          color: e,
          onChange: r,
          alpha: !0,
          prefixed: !0,
          className: M.input,
          onKeyDown: l
        }
      )
    ] }) })
  ] });
}
function pr({ onColorChange: e, transparentOption: t = !1, value: r, hexInputLabel: o }) {
  const n = [
    z("betterplace-color-green-500"),
    z("betterplace-color-green-700"),
    z("betterplace-color-green-900"),
    z("betterplace-color-yellow-400"),
    z("betterplace-me-color-orange-500"),
    z("betterplace-color-red-700"),
    z("betterplace-color-red-900"),
    // themeColor('orange-700'),
    // themeColor('blue-400'),
    // themeColor('blue-700'),
    // themeColor('blue-900'),
    z("betterplace-color-purple-400"),
    z("betterplace-color-purple-700"),
    z("betterplace-color-gray-500"),
    z("betterplace-color-gray-700"),
    z("betterplace-color-gray-900")
  ].filter(Boolean);
  return t && n.push("#00000000"), /* @__PURE__ */ s.jsx("div", { className: "input-group", children: /* @__PURE__ */ s.jsx(
    pt,
    {
      color: `#${r === "transparent" ? "00000000" : r}`,
      presetColors: n,
      onChange: e,
      hexInputLabel: o
    }
  ) });
}
const mt = "_copyTextContainer_t34qr_2", bt = "_copyTextInput_t34qr_7", we = {
  copyTextContainer: mt,
  copyTextInput: bt
}, ft = "_modal_36g8m_1", ht = "_modalInner_36g8m_51", gt = "_modalHeader_36g8m_55", yt = "_closeButton_36g8m_70", xt = "_closeIcon_36g8m_76", wt = "_modalContent_36g8m_80", vt = "_modalActions_36g8m_84", U = {
  modal: ft,
  modalInner: ht,
  modalHeader: gt,
  closeButton: yt,
  closeIcon: xt,
  modalContent: wt,
  modalActions: vt
};
function _t(e) {
  e && (!("close" in e) || typeof e.close != "function" || e.close());
}
function mr({
  id: e,
  isOpen: t,
  onClose: r,
  onOpen: o,
  onCloseComplete: n,
  onOpenComplete: a,
  title: c,
  customActions: l,
  children: p,
  manualFocusRef: i,
  closeText: d,
  closeOnClickOutside: g = !0,
  hideHeader: _ = !1,
  hideActions: T = !1,
  classNames: k = {}
}) {
  const x = L(null), h = L(null), [m, b] = $(!0), C = f(() => i || (h.current ? h : x), [i]), E = ee(), u = e || E, w = f((y) => {
    var v;
    if (y.key !== "Tab") return;
    const S = (v = x.current) == null ? void 0 : v.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    ), F = S == null ? void 0 : S[0], K = S == null ? void 0 : S[S.length - 1];
    if (y.shiftKey && document.activeElement === F) {
      y.preventDefault(), K == null || K.focus();
      return;
    }
    !y.shiftKey && document.activeElement === K && (y.preventDefault(), F == null || F.focus());
  }, []), A = f(() => {
    n == null || n(), b(!0);
  }, [n]), O = f(() => {
    var y;
    a == null || a(), b(!0), (y = x.current) == null || y.removeAttribute("inert");
  }, [a]);
  I(() => {
    var S;
    b(!1);
    const y = x.current;
    if (y) {
      if (t) {
        y.showModal();
        const F = C();
        (S = F == null ? void 0 : F.current) == null || S.focus(), y.removeEventListener("transitionend", A), y.addEventListener("keydown", w), y.addEventListener("transitionend", O), o == null || o();
      } else
        y.removeEventListener("keydown", w), y.removeEventListener("transitionend", O), y.addEventListener("transitionend", A), _t(y), y.setAttribute("inert", "");
      return () => {
        y == null || y.removeEventListener("keydown", w), y.removeEventListener("transitionend", A), y.removeEventListener("transitionend", O);
      };
    }
  }, [C, w, t, A, o, O]);
  const Y = f(
    (y) => {
      const { current: S } = x;
      y.target === S && r();
    },
    [r]
  );
  return (
    // Disabled because HTML dialog brings keyboard interaction with it
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    /* @__PURE__ */ s.jsx(
      "dialog",
      {
        id: u,
        ref: x,
        className: N(U.modal, k.modal),
        onCancel: r,
        onClick: g ? Y : void 0,
        "aria-labelledby": `modal-title-${u}`,
        "aria-modal": "true",
        title: c,
        tabIndex: -1,
        children: /* @__PURE__ */ s.jsxs(ke, { space: "400", className: N(U.modalInner, k.modalInner), children: [
          !_ && /* @__PURE__ */ s.jsxs("div", { className: N(U.modalHeader, k.titleContainer), children: [
            /* @__PURE__ */ s.jsx(Ee, { level: "h200", as: "h2", id: `modal-title-${u}`, children: c }),
            /* @__PURE__ */ s.jsx(
              Be,
              {
                kind: "plain",
                className: U.closeButton,
                onClick: r,
                ref: h,
                type: "button",
                iconName: "x",
                iconProps: { title: d, color: "fg-content-primary" }
              }
            )
          ] }),
          /* @__PURE__ */ s.jsx("div", { className: N(U.modalContent, k.contentContainer), children: (t || !m) && p }),
          !T && /* @__PURE__ */ s.jsx("div", { className: N(U.modalActions, k.actionsContainer), children: l || /* @__PURE__ */ s.jsx(Oe, { onClick: r, type: "button", children: d }) })
        ] })
      }
    )
  );
}
const kt = "_cluster_1iwsd_1", At = "_clustered_1iwsd_7", Tt = "_justified_1iwsd_11", oe = {
  cluster: kt,
  clustered: At,
  justified: Tt
};
function Se({
  as: e = "div",
  alignment: t = "clustered",
  className: r,
  style: o,
  space: n = "200",
  ...a
}) {
  return /* @__PURE__ */ s.jsx(
    e,
    {
      ...a,
      className: N(oe.cluster, r, {
        [oe.justified]: t === "justified",
        [oe.clustered]: t === "clustered"
      }),
      style: {
        ...o,
        "--space": `var(--betterplace-spacing-${n})`
      },
      children: a.children
    }
  );
}
const br = ({
  textToCopy: e,
  showPreview: t,
  previewName: r,
  previewLabel: o,
  children: n,
  onTextCopied: a,
  onError: c
}) => {
  const l = (d) => {
    d && c(d);
  }, p = (d) => {
    a && a(d);
  }, i = () => {
    e && He(e).then(() => p(e)).catch((d) => l(d));
  };
  return /* @__PURE__ */ s.jsxs(Se, { className: we.copyTextContainer, children: [
    t && /* @__PURE__ */ s.jsx(
      _e,
      {
        className: we.copyTextInput,
        disabled: !0,
        label: o,
        name: r,
        value: e
      }
    ),
    n(i)
  ] });
}, Ct = "_fieldset_hid4k_1", Nt = "_fieldContainer_hid4k_30", St = "_groupLabel_hid4k_34", Lt = "_labelLarge_hid4k_39", jt = "_groupHint_hid4k_43", It = "_groupError_hid4k_48", Ft = "_errorMessage_hid4k_57", zt = "_disabledLabels_hid4k_64", X = {
  fieldset: Ct,
  fieldContainer: Nt,
  groupLabel: St,
  labelLarge: Lt,
  groupHint: jt,
  groupError: It,
  errorMessage: Ft,
  disabledLabels: zt
};
function Et({ className: e, hint: t, required: r, children: o }) {
  return /* @__PURE__ */ s.jsxs("legend", { className: e, children: [
    /* @__PURE__ */ s.jsxs("div", { className: X.groupLabel, children: [
      o,
      r && /* @__PURE__ */ s.jsx("span", { "aria-label": "(required)", children: "*" })
    ] }),
    t ? /* @__PURE__ */ s.jsx("div", { className: X.groupHint, children: t }) : null
  ] });
}
function fr({
  children: e,
  classNames: t,
  legend: r,
  hint: o,
  required: n,
  error: a,
  layout: c = "horizontal",
  layoutProps: l = {
    space: "none"
  },
  errorDisplay: p = "all",
  ...i
}) {
  const d = N(X.fieldContainer, l == null ? void 0 : l.className, {
    [X.groupError]: !!a && p !== "text"
  }), g = c === "vertical" ? ke : Se, _ = ee(), T = i.id || _, k = a ? `${T}-error` : void 0;
  return /* @__PURE__ */ s.jsxs(
    "fieldset",
    {
      id: T,
      className: N(X.fieldset, t == null ? void 0 : t.fieldset),
      "aria-describedby": k,
      ...i,
      children: [
        r || o ? /* @__PURE__ */ s.jsx(Et, { required: n, hint: o, className: t == null ? void 0 : t.legend, children: r }) : null,
        /* @__PURE__ */ s.jsx(g, { className: d, ...l, children: e }),
        a && p !== "border" ? /* @__PURE__ */ s.jsx("p", { id: k, className: X.errorMessage, "aria-live": "polite", role: "alert", children: a }) : null
      ]
    }
  );
}
const hr = q(function({ onChange: t, onClick: r, ...o }, n) {
  const { disabled: a } = o, c = a ? P : t, l = a ? P : r;
  return /* @__PURE__ */ s.jsx($e, { ...o, onChange: c, onClick: l, ref: n });
}), gr = q(function({ onChange: t, onClick: r, ...o }, n) {
  const { disabled: a } = o, c = a ? P : t, l = a ? P : r;
  return /* @__PURE__ */ s.jsx(Me, { ...o, onChange: c, onClick: l, ref: n });
}), Bt = "_input_xcovg_1", Ot = "_growWrap_xcovg_2", V = {
  input: Bt,
  growWrap: Ot,
  "input--error": "_input--error_xcovg_24"
}, yr = q(function({ label: t, name: r, description: o, error: n, warning: a, id: c, required: l, className: p, onChange: i, errorDisplay: d, rows: g = 1, ..._ }, T) {
  const k = L(null), x = [];
  return n && x.push(`${r}-error`), a && x.push(`${r}-warning`), o && x.push(`${r}-description`), /* @__PURE__ */ s.jsx(
    De,
    {
      label: t,
      name: r,
      description: o,
      error: n,
      warning: a,
      id: c || r,
      required: l,
      errorDisplay: d,
      /**
       * The following trick is used for the auto resizing textarea: https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
       * We need to make sure the same styles are applied to both the grow wrapper and the input
       */
      children: /* @__PURE__ */ s.jsx("div", { className: N(V.growWrap, p, { [V.wrapError]: !!n }), ref: k, children: /* @__PURE__ */ s.jsx(
        "textarea",
        {
          ..._,
          ref: T,
          id: c || r,
          name: r,
          "aria-describedby": x.length ? x.join(" ") : void 0,
          "aria-invalid": !!n,
          "aria-required": l,
          className: N(V.input, p, { [V["input--error"]]: !!n }),
          rows: g,
          onChange: (h) => {
            var m;
            (m = k.current) == null || m.setAttribute("data-replicated-value", h.target.value), i && i(h);
          }
        }
      ) })
    }
  );
}), $t = "_button_akv9v_1", Mt = "_hiddenInput_akv9v_62", ne = {
  button: $t,
  hiddenInput: Mt,
  "size-large": "_size-large_akv9v_68",
  "size-default": "_size-default_akv9v_73",
  "size-small": "_size-small_akv9v_78"
}, xr = q(function({
  id: t,
  children: r,
  value: o,
  name: n,
  onChange: a,
  onClick: c,
  className: l,
  type: p = "radio",
  size: i = "default",
  disabled_: d,
  disabled: g,
  ..._
}, T) {
  const k = N(ne.button, ne[`size-${i}`], l), x = ee(), m = `${t || n || x}-${String(o)}`, b = g || d;
  return /* @__PURE__ */ s.jsxs("label", { htmlFor: m, className: k, children: [
    /* @__PURE__ */ s.jsx(
      "input",
      {
        ..._,
        ref: T,
        id: m,
        "aria-disabled": b,
        type: p,
        value: o,
        name: n,
        onClick: b ? P : c,
        onChange: b ? P : a,
        className: ne.hiddenInput,
        disabled: d
      }
    ),
    r
  ] });
}), Dt = "_tooltipContent_15m5t_1", Pt = "_tooltipContainer_15m5t_11", Rt = "_tooltipArrow_15m5t_19", Ht = "_bottomTooltipArrow_15m5t_50", Wt = "_topTooltipArrow_15m5t_54", qt = "_spyElement_15m5t_58", Kt = "_triggerContainer_15m5t_64", Ut = "_nonFocusableOutline_15m5t_74", H = {
  tooltipContent: Dt,
  tooltipContainer: Pt,
  tooltipArrow: Rt,
  bottomTooltipArrow: Ht,
  topTooltipArrow: Wt,
  spyElement: qt,
  triggerContainer: Kt,
  nonFocusableOutline: Ut
}, W = {
  TOP: "top",
  BOTTOM: "bottom"
}, Xt = {
  a: "link",
  area: "link",
  article: "article",
  aside: "complementary",
  button: "button",
  details: "group",
  dfn: "term",
  dialog: "dialog",
  fieldset: "group",
  figure: "figure",
  footer: "contentinfo",
  form: "form",
  h1: "heading",
  h2: "heading",
  h3: "heading",
  h4: "heading",
  h5: "heading",
  h6: "heading",
  header: "banner",
  hr: "separator",
  iframe: "document",
  img: "img",
  input: null,
  // for type purposes this value needs to be included
  li: "listitem",
  main: "main",
  menu: "list",
  menuitem: "menuitem",
  meter: "meter",
  nav: "navigation",
  ol: "list",
  option: "option",
  progress: "progressbar",
  section: "region",
  select: "listbox",
  summary: "button",
  table: "table",
  tbody: "rowgroup",
  td: "cell",
  textarea: "textbox",
  tfoot: "rowgroup",
  th: "columnheader",
  thead: "rowgroup",
  tr: "row",
  ul: "list"
}, Yt = {
  button: "button",
  checkbox: "checkbox",
  radio: "radio",
  range: "slider",
  text: "textbox",
  password: "textbox",
  email: "textbox",
  search: "searchbox",
  number: "spinbutton",
  tel: "textbox",
  url: "textbox",
  submit: "button",
  reset: "button",
  image: "button",
  file: "textbox"
}, Gt = ["INPUT", "SELECT", "TEXTAREA", "A", "BUTTON", "AREA"];
function Qt(e) {
  return !e || !e.trim() ? /* @__PURE__ */ new Set() : new Set(e.trim().split(/\s+/));
}
function Vt(e, t) {
  return [...Qt(e).add(t)].join(" ");
}
function Jt(e) {
  const t = e.getAttribute("role");
  if (t) return t;
  const r = e.tagName.toLowerCase();
  if (r === "input") {
    const n = e.getAttribute("type");
    return n ? Yt[n] : "textbox";
  }
  const o = Xt[r];
  return o || "button";
}
function Le(e) {
  if (!e || !(e instanceof HTMLElement)) return null;
  if (Gt.includes(e.tagName))
    return e;
  for (const t of e.children) {
    const r = Le(t);
    if (r)
      return r;
  }
  return null;
}
function Zt(e, t) {
  return JSON.stringify(e) === JSON.stringify(t);
}
function je(e) {
  return (t) => t === e || t && Zt(t, e) ? t : e;
}
const ae = {
  enter: "Enter",
  space: " ",
  tab: "Tab",
  escape: "Escape"
};
function ve({ x: e, position: t }) {
  return /* @__PURE__ */ s.jsx(
    "div",
    {
      className: N(H.tooltipArrow, {
        [H.bottomTooltipArrow]: t === W.BOTTOM,
        [H.topTooltipArrow]: t === W.TOP
      }),
      style: { transform: `translateX(${e}px) ${t === W.BOTTOM ? " rotate(180deg)" : ""}` }
    }
  );
}
function er() {
  const e = document.getElementById("bp-portal-target");
  if (e) return e;
  const t = document.createElement("div");
  return t.id = "bp-portal-target", t.style.position = "absolute", t.setAttribute("role", "tooltip"), document.body.appendChild(t), t;
}
function tr({
  triggerRect: e,
  id: t,
  onTooltipLeave: r,
  children: o,
  triggerEvents: n
}) {
  const a = L(null), [c, l] = $(null), [p, i] = $(null), d = f(() => {
    var C;
    const b = ((C = a == null ? void 0 : a.current) == null ? void 0 : C.getBoundingClientRect()) ?? null;
    l(je(b && { width: b.width, height: b.height }));
  }, []), g = f(() => {
    const b = document.body.getBoundingClientRect();
    i(b.width);
  }, []);
  Ae(() => {
    d(), g();
  }, [d, g]);
  const _ = f(() => {
    window.requestAnimationFrame(() => {
      d(), g();
    });
  }, [d, g]), T = f(
    (b) => {
      n != null && n.includes("hover") && r(b);
    },
    [r, n]
  );
  I(() => (window.addEventListener("resize", _), () => {
    window.removeEventListener("resize", _);
  }), [_]);
  const { x: k, y: x, arrowTooltipOffsetX: h, position: m } = D(
    () => or(e, c, p),
    [e, c, p]
  );
  return Re(
    /* @__PURE__ */ s.jsxs(
      "div",
      {
        id: t,
        className: H.tooltipContainer,
        ref: a,
        onPointerLeave: T,
        style: {
          transform: `translate3d(${k}px, ${x}px, 0)`
        },
        children: [
          m === W.BOTTOM && /* @__PURE__ */ s.jsx(ve, { position: m, x: h }),
          /* @__PURE__ */ s.jsx("div", { className: H.tooltipContent, children: o }),
          m === W.TOP && /* @__PURE__ */ s.jsx(ve, { position: m, x: h })
        ]
      }
    ),
    er()
  );
}
function rr({ children: e, content: t, id: r, triggerEvents: o }) {
  const n = ee(), a = r || n, c = L(null), [l, p] = $(null);
  I(() => {
    var F;
    const u = (F = c.current) == null ? void 0 : F.nextSibling;
    if (!u) return;
    const w = Le(u), A = w ?? u, Y = A.getAttribute("aria-roledescription") ?? Jt(A) ?? "button";
    A.setAttribute("role", "button"), w || (A == null || A.setAttribute("tabindex", "0"), A == null || A.classList.add(H.nonFocusableOutline)), A.setAttribute("aria-roledescription", Y);
    const y = A.getAttribute("aria-describedby");
    (y == null ? void 0 : y.includes(a)) || A.setAttribute("aria-describedby", Vt(y, a));
  }, [e, a]);
  const i = f(() => {
    var A;
    const u = (A = c == null ? void 0 : c.current) == null ? void 0 : A.nextSibling;
    let w = (u == null ? void 0 : u.getBoundingClientRect()) ?? null;
    w != null && w.height || (w = null), p(je(w && { left: w.left, top: w.top, bottom: w.bottom, width: w.width }));
  }, [c]), d = f(() => {
    l && i();
  }, [i, l]), g = f(() => {
    p(null);
  }, []), _ = f(() => {
    l ? g() : i();
  }, [i, g, l]), T = f(
    (u) => {
      const w = document.getElementById(n);
      if (u.relatedTarget instanceof Node && (w != null && w.contains(u.relatedTarget)))
        return u.preventDefault();
      g();
    },
    [g, n]
  ), k = f(
    (u) => {
      var w, A;
      if (u.relatedTarget instanceof Node && ((A = (w = c == null ? void 0 : c.current) == null ? void 0 : w.nextSibling) != null && A.contains(u.relatedTarget)))
        return u.preventDefault();
      g();
    },
    [g]
  ), x = f(
    (u) => {
      o != null && o.includes("click") && (u.preventDefault(), _());
    },
    [_, o]
  ), h = f(
    (u) => {
      if (o != null && o.includes("keypress") && (u.key === ae.enter || u.key === ae.space)) {
        u.preventDefault(), _();
        return;
      }
      u.key === ae.escape && g();
    },
    [g, _, o]
  ), m = f(() => {
    o != null && o.includes("focus") && i();
  }, [i, o]), b = f(() => {
    o != null && o.includes("focus") && g();
  }, [g, o]), C = f(() => {
    o != null && o.includes("hover") && i();
  }, [i, o]), E = f(
    (u) => {
      o != null && o.includes("hover") && T(u);
    },
    [T, o]
  );
  return I(() => {
    var w;
    const u = (w = c.current) == null ? void 0 : w.nextSibling;
    return u == null || u.addEventListener("click", x), u.addEventListener("pointerenter", C), u.addEventListener("pointerleave", E), u.addEventListener("focus", m), u.addEventListener("focusin", m), u.addEventListener("focusout", b), u.addEventListener("keydown", h), () => {
      u.removeEventListener("click", x), u.removeEventListener("pointerenter", C), u.removeEventListener("pointerleave", E), u.removeEventListener("focus", m), u.removeEventListener("focusin", m), u.removeEventListener("focusout", b), u.removeEventListener("keydown", h);
    };
  }, [x, m, h, C, E, _, c, b, e]), I(() => (window.addEventListener("resize", d), () => {
    window.removeEventListener("resize", d);
  }), [d]), Ae(() => {
    d();
  }, [e, d]), /* @__PURE__ */ s.jsxs(s.Fragment, { children: [
    /* @__PURE__ */ s.jsx("div", { ref: c, className: H.spyElement }),
    e,
    l && /* @__PURE__ */ s.jsx(
      tr,
      {
        triggerRect: l,
        id: a,
        onTooltipLeave: k,
        triggerEvents: o,
        children: t
      }
    )
  ] });
}
function wr({ children: e, content: t, id: r, triggerEvents: o = ["hover", "focus"], inactive: n }) {
  const [a, c] = $(!1);
  return I(() => (c(!0), () => {
    c(!1);
  }), []), !a || n ? e : /* @__PURE__ */ s.jsx(rr, { content: t, id: r, triggerEvents: o, children: e });
}
function or(e, t, r, o = 20, n = 17) {
  let a = 0, c = 0, l = 0, p = 0, i = W.TOP;
  if (!e || !t || !r) return { x: a, y: c, arrowTooltipOffsetX: p, position: i };
  const d = t.width > r ? 0 : Math.min(o, (r - t.width) / 2), g = t.width / 2, _ = e.width / 2;
  a = e.left - g + _;
  const T = a;
  l = a + t.width, a < d ? a = d : l > r - d && (a -= l - (r - d)), p = T - a + g - n;
  const k = p + 2 * n - t.width;
  if (k > 0) {
    const x = Math.min(k, d);
    a += x, p -= x;
  }
  return c = e.top - t.height, c < 0 && (i = W.BOTTOM, c = e.bottom), { x: a, y: c, arrowTooltipOffsetX: p, position: i };
}
const nr = "_tooltipTrigger_36bik_1", ar = {
  tooltipTrigger: nr
}, vr = ({ type: e = "question", title: t = "More information", className: r }) => /* @__PURE__ */ s.jsx("button", { className: N(ar.tooltipTrigger, r), type: "button", children: /* @__PURE__ */ s.jsx(Pe, { name: e, title: t }) });
function cr(e, t, r) {
  const o = L(null), n = L(null), [a, c] = $(null);
  return I(() => {
    n.current = t ?? null;
  }, [t]), I(() => (o.current = new MutationObserver((l) => {
    for (const p of l)
      (typeof n.current != "function" || n.current(p)) && c(p);
  }), o.current.observe(e.current, r), () => {
    var l;
    (l = o.current) == null || l.disconnect();
  }), []), a;
}
function _r(e, t) {
  const r = f(
    (a) => a.type === "attributes" && a.attributeName === t,
    [t]
  ), o = cr(e, r, { attributes: !0 });
  return D(
    () => {
      var a;
      return { oldValue: (o == null ? void 0 : o.oldValue) ?? null, newValue: (a = e.current) == null ? void 0 : a.getAttribute(t) };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [o, t]
  );
}
export {
  Tr as Alert,
  ur as AllowedInputTypes,
  Ke as AmountField,
  Cr as Badge,
  Oe as Button,
  Nr as Card,
  dr as Checkbox,
  pr as ColorInput,
  br as CopyText,
  hr as Dropdown,
  fr as Fieldset,
  Ee as Heading,
  Pe as Icon,
  Be as IconButton,
  Sr as JSONPrettyPrint,
  Lr as LayoutCenter,
  Se as LayoutCluster,
  jr as LayoutGrid,
  mr as LayoutModal,
  ke as LayoutStack,
  W as Position,
  gr as RadioButton,
  Ir as RangeInput,
  _e as TextInput,
  Fr as TextLink,
  yr as Textarea,
  zr as Tile,
  xr as ToggleButton,
  wr as Tooltip,
  vr as TooltipTrigger,
  _r as useAttributeObserver,
  cr as useMutationObserver
};
